<template>
  <div class="ma-0 pa-0 treatment-plan">
    <div class="form-table">
      <v-row class="top">
        <v-col cols="2" class="text-center"></v-col>
        <v-col cols="5" class="text-center">
          <h3>{{ $t("examination.treatmentPlan.rightEye10") }}</h3>
        </v-col>
        <v-col cols="5" class="text-center">
          <h3>{{ $t("examination.treatmentPlan.leftEye10") }}</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2" class="text-center"> Procedure/<br />Accessment</v-col>
        <v-col v-for="pos of EyeType" :key="pos" cols="5" class="text-center">
          <p class="chip-container">
            <v-chip
              v-for="item in collectionsTreatment[`${pos}`]"
              v-bind:key="item.id"
              @click:close="deleteTreatment(item.id, pos)"
              close
              close-icon="mdi-close"
              label
              class="mt-2 mr-2 float-left"
            >
              {{ item.textStr }}
            </v-chip>
          </p>
          <v-btn
            icon
            outlined
            color="#0065FF"
            width="20"
            height="20"
            @click="showSearchICDPopup(pos)"
            v-bind:disabled="isFinishExamination === true ? true : false"
          >
            <v-icon dark size="20"> mdi-plus </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2" class="text-center">
          {{ $t("examination.treatmentPlan.primaryDiagnosis") }}</v-col
        >
        <v-col v-for="pos of EyeType" :key="pos" cols="5" class="text-center">
          <p class="chip-container">
            <v-chip
              v-for="item in collectionsDiagnosis[`${pos}`]"
              v-bind:key="item.id"
              @click:close="deleteDiagnosis(item.id, pos)"
              close
              close-icon="mdi-close"
              label
              class="mt-2 mr-2 float-left"
              color="#0052cc"
              text-color="#ffffff"
            >
              {{ item.textStr }}
            </v-chip>
          </p>
          <span v-show="pos == 1 && descPrimaryRight" >
            <h4 class="float-left">DESC:</h4>
            <p class="chip-container">
              <v-chip
                label
                class="mt-2 mr-2 float-left"
                color="#ffb703"
                @click="getDiagnosisPrimaryDetail(pos)"
              >
              {{ descPrimaryRight }}
              </v-chip>
            </p>
          </span>
          <span v-show="pos == 2 && descPrimaryLeft" >
            <h4 class="float-left">DESC:</h4>
            <p class="chip-container">
              <v-chip
                label
                class="mt-2 mr-2 float-left"
                color="#ffb703"
                @click="getDiagnosisPrimaryDetail(pos)"
              >
                {{ descPrimaryLeft }}
              </v-chip>
            </p>
          </span>
          <v-btn
            v-if="showAddMore(pos)"
            icon
            outlined
            color="#0065FF"
            width="20"
            height="20"
            @click="showSearchICDDPopup(DiagnosisType.Primary, pos)"
            v-bind:disabled="isFinishExamination === true ? true : false"
          >
            <v-icon dark size="20"> mdi-plus </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <SearchICDPopup
        ref="SearchICDPopup"
        @onSaveTreatmentSuccess="handleSaveTreatmentSuccess"
      />
      <SearchICDDPopup
        ref="SearchICDDPopup"
        @onSaveDiagnosisSuccess="handleSaveDiagnosisSuccess"
      />
    </div>
    <MedicationComponent :episodeRecordID="episodeRecordID" />
  </div>
</template>

<script>
//import EpisodeMedication from "@/components/common/Medication/EpisodeMedication";
import SearchICDDPopup from "@/components/common/Patient/SearchICDDPopup";
import SearchICDPopup from "@/components/common/Patient/SearchICDPopup";
import MedicationComponent from "@/components/common/Medication/MedicationContainer";
import DiagnosisService from "@/services/diagnosis";
import TreatmentService from "@/services/treatment";
import WorkListService from "@/services/work-list";
import EpisodeService from "@/services/episode";
import { TreatmentType, DiagnosisType, EyeType } from "@/plugins/constant";

export default {
  data() {
    return {
      TreatmentType,
      DiagnosisType,
      EyeType,
      collectionsDiagnosis: {},
      collectionsTreatment: {},
      episodeID: null,
      isFinishExamination: false,
      episodeRecordID: null,
      descPrimaryLeft: "",
      descPrimaryRight: "",
    };
  },
  components: {
    SearchICDDPopup,
    SearchICDPopup,
    MedicationComponent,
    //EpisodeMedication,
  },
  async created() {
    this.renderDiagnosis();
    this.renderTreatment();
    this.getEpisodeID(this.$route.params.episodeRecordID);
    this.episodeRecordID = this.$route.params.episodeRecordID;
  },
  methods: {
    showError(message) {
      this.$toast.error(message);
    },
    showSearchICDDPopup(type, pos) {
      var hiddenItems = this.collectionsDiagnosis[`${pos}`];
      hiddenItems = hiddenItems.map((i) => ({ code: i.icdDxCode }));
      this.$refs.SearchICDDPopup.openPopup(hiddenItems, type, pos);
    },
    showSearchICDPopup(pos) {
      var hiddenItems = this.collectionsTreatment[`${pos}`];
      hiddenItems = hiddenItems.map((i) => ({ code: i.icdCode }));
      this.$refs.SearchICDPopup.openPopup(
        hiddenItems,
        TreatmentType.Proceduce,
        pos
      );
    },
    async getDiagnosisPrimaryDetail(pos) {
      var episodeRecordID = this.$route.params.episodeRecordID;
      const apiResult = await DiagnosisService.GetDiagnosisPrimaryDetail(episodeRecordID, pos);
      this.$refs.SearchICDDPopup.openPopup([], DiagnosisType.Primary, pos, apiResult);
    },
    async renderDiagnosis() {
      const apiResult = await DiagnosisService.SearchListDiagnosis(
        this.$route.params.episodeRecordID,
        1,
        100
      );
      var { items } = apiResult;
      items.forEach((item) => {
        item.textStr = `${item.icdDxCode} - ${item.icdDxDescription}`;
      });
      for (var pos in EyeType) {
        this.collectionsDiagnosis[`${EyeType[pos]}`] = items.filter(
          (i) =>
            i.diagnosisCode === DiagnosisType.Primary &&
            i.diagnosisCodePosition == EyeType[pos]
        );
        if(pos == "Left"){
          this.descPrimaryLeft = this.collectionsDiagnosis[`${EyeType[pos]}`][0]?.description;
        }
        if(pos == "Right"){
          this.descPrimaryRight = this.collectionsDiagnosis[`${EyeType[pos]}`][0]?.description;
        }
      }
      this.$forceUpdate();
    },
    async renderTreatment() {
      const apiResult = await TreatmentService.SearchListTreatment(
        this.$route.params.episodeRecordID,
        1,
        100
      );
      var { items } = apiResult;
      items.forEach((item) => {
        item.textStr = `${item.icdCode} - ${item.icdDescription}`;
      });
      for (var pos in EyeType) {
        this.collectionsTreatment[`${EyeType[pos]}`] = items.filter(
          (i) =>
            i.treatmentCode === TreatmentType.Proceduce &&
            i.treatmentCodePosition == EyeType[pos]
        );
      }
      this.$forceUpdate();
    },
    async deleteDiagnosis(diagnosisID, pos) {
      var result = await DiagnosisService.DeleteDiagnosisByID(diagnosisID);
      if (result.error) {
        this.showError("This diagnosis cannot be deleted!");
        return;
      }
      var items = this.collectionsDiagnosis[`${pos}`];
      var ind = items.findIndex((i) => i.id == diagnosisID);
      items.splice(ind, 1);
      this.collectionsDiagnosis[`${pos}`] = items;
      if(pos == 1) {
        this.descPrimaryRight = "";
      }
      if (pos == 2) {
        this.descPrimaryLeft ="";
      }
      this.$forceUpdate();
    },
    async deleteTreatment(diagnosisID, pos) {
      var result = await TreatmentService.DeleteTreatmentByID(diagnosisID);
      if (result.error) {
        this.showError("This treatment cannot be deleted!");
        return;
      }
      var items = this.collectionsTreatment[`${pos}`];
      var ind = items.findIndex((i) => i.id == diagnosisID);
      items.splice(ind, 1);
      this.collectionsTreatment[`${pos}`] = items;
      this.$forceUpdate();
    },
    async getEpisodeID(episodeRecordID) {
      if (!episodeRecordID) return;
      var result = await WorkListService.GetByEpisodeRecordID(episodeRecordID);
      if (!result || result.error) {
        this.showError(
          "Cannot get episodeID at the moment! Please try again later."
        );
      }
      this.episodeID = result.episodeID;
      await this.getEpisodeInfo();
    },
    async getEpisodeInfo() {
      const result = await EpisodeService.getEpisodeDetail(this.episodeID);
      if (!result || result.error) {
        this.showError(
          "Can not get episode information. Please try again later."
        );
        return;
      }
      if (result.statusID == 2) {
        this.isFinishExamination = true;
      }
    },
    handleSaveDiagnosisSuccess() {
      this.renderDiagnosis();
    },
    handleSaveTreatmentSuccess() {
      this.renderTreatment();
    },
    showAddMore(pos) {
      return this.collectionsDiagnosis[`${pos}`] &&
        this.collectionsDiagnosis[`${pos}`].length == 0
        ? true
        : false;
    },
  },
};
</script>

<style lang="scss">
.treatment-plan {
  .form-table {
    border: 1px solid rgba(0, 0, 0, 0.6);
    border-right: 0px;
    width: 50vw;
    min-width: 60vw;
    .row {
      margin: 0px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.6);
      margin-bottom: -1px;
      .col {
        border-right: 1px solid rgba(0, 0, 0, 0.6);
      }
    }
  }
  p.chip-container {
    width: 100%;
    display: inline-block;
  }
  .v-chip {
    display: inline-table;
    white-space: normal;
    text-align: left;
  }
}
</style>
