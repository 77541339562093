<template>
  <v-dialog v-model="showDialog" max-width="750px">
    <v-card>
      <v-form>
        <v-container fluid>
          <v-row class="form-diagnosis">
            <v-col cols="11">
              <h3 style="margin-bottom: 15px">{{ name }}</h3>
              <v-autocomplete
                v-model="selections"
                :items="items"
                chips
                color="blue-grey lighten-2"
                :label="$t('examination.treatmentPlan.select')"
                item-text="label"
                item-value="value"
                autofocus
                multiple
                flat
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="1">
              <v-checkbox
                v-model="bothEyes"
                class="mt-10"
                style="margin: auto"
                :label="$t('examination.treatmentPlan.bothEye')"
                color="orange"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-btn
                ripple
                color="#0065FF"
                class="px-4 py-3 white--text"
                @click="addTreatment()"
              >
                {{ $t("examination.treatmentPlan.addTreatment") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import TreatmentService from "@/services/treatment";
import { EyeType } from "@/plugins/constant";
import i18n from "@/plugins/i18n";

export default {
  data() {
    return {
      bothEyes: true,
      showDialog: false,
      name: i18n.t("examination.treatmentPlan.searchICD"),
      selections: [],
      items: [],
      hiddenItems: [],
      fullItems: [],
      type: "",
      pos: EyeType.Right,
    };
  },
  watch: {},
  async created() {
    await this.searchAllListICD();
    console.log(localStorage.getItem("LOCATE_LANGUAGE"));
  },
  methods: {
    showError(message) {
      this.$toast.error(message);
    },
    async openPopup(hiddenItems = [], type, pos) {
      this.hiddenItems = hiddenItems;
      this.renderItemOptions();
      this.selections = [];
      this.showDialog = true;
      this.pos = pos;
      this.type = type;
      this.bothEyes = true;
    },
    renderItemOptions() {
      this.items = this.fullItems.filter(
        (i) => !this.hiddenItems.map((h) => h.code).includes(i.code)
      );
    },
    async searchAllListICD() {
      const apiResult = await TreatmentService.SearchListICD("", 1, 1, 10000);
      var items = apiResult.items.map((i) => ({
        ...i,
        value: i.id,
        label: i.description,
      }));
      this.fullItems = items;
      this.renderItemOptions();
    },
    async addTreatment() {
      let episodeRecordID = this.$route.params.episodeRecordID;
      var result = [];
      var bodyData = {};
      if (this.bothEyes == true) {
        for (var pos in EyeType) {
          bodyData = {
            episodeRecordID: episodeRecordID,
            treatmentCode: this.type,
            treatmentCodePosition: EyeType[pos],
            listICDIDs: this.selections,
          };
          result = await TreatmentService.CreateTreatmentWithListICD(bodyData);
        }
      } else if (this.bothEyes == false) {
        bodyData = {
          episodeRecordID: episodeRecordID,
          treatmentCode: this.type,
          treatmentCodePosition: this.pos,
          listICDIDs: this.selections,
        };
        result = await TreatmentService.CreateTreatmentWithListICD(bodyData);
      }
      if (!result || result.error) {
        this.showError(result.message);
        return;
      }
      this.$emit("onSaveTreatmentSuccess", result);
      this.showDialog = false;
    },
  },
};
</script>

<style>
.form-diagnosis {
  margin-bottom: 20px;
}
.white--text {
  float: right;
}
</style>